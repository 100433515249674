import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import { useStaticQuery, graphql, Link } from "gatsby"
import { PressSectionStyles } from "./PressSection.styles"
import Img from "gatsby-image"

const PressSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(
        filter: {
          category: { elemMatch: { slug: { eq: "updates" } } }
          featured: { eq: true }
        }
        sort: { fields: publishedOn, order: DESC }
      ) {
        edges {
          node {
            id
            slug
            featured
            title
            publishedOn(formatString: "DD MMM, YYYY")
            category {
              name
            }
            externalLink
          }
        }
      }
    }
  `)
  const featuredMediaPosts = data.allContentfulBlogPost.edges;
  return (
    <PressSectionStyles>
      <Container className="py-5 px-5 px-lg-0">
        <HeadingWithBg text="Press" />
        <Row>
          <Col>
            <h2 className="h1 my-4 my-lg-5 font-weight-bold secondary-blue-2-text heading announcement-header">
              Recent Press Releases
            </h2>
            {featuredMediaPosts.map(({ node: featuredMediaPost, id }) => (
              <div key={id} className="my-5">
                <div className="d-flex primary-blue-text my-2 align-items-center flex-wrap">
                  <div>{featuredMediaPost.publishedOn} . Company Update</div>
                  {featuredMediaPost.featured ? (
                    <div className="featured-tag mt-3 mt-lg-0 mx-lg-4 px-5 pt-2 pb-1 text-uppercase ">
                      Featured
                    </div>
                  ) : null}
                </div>
                <h2 className="my-4 primary-blue-text">{featuredMediaPost.title}</h2>
                <Link
                  className="read-more secondary-blue-2-text mx-4"
                  to={featuredMediaPosts.externalLink || `/blog/${featuredMediaPost.slug}/`}
                >
                  Read More
                </Link>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </PressSectionStyles>
  )
}

export default PressSection
