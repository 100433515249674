import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const MediaSectionStyles = styled.section`
  background-color: white;
  .announcement-header h1 {
    font-weight: 800;
    font-size: 2.5rem;
    @media (max-width: 992px) {
      font-size: 2rem;
    }
  }
  .media-post {
    border-bottom: 1px solid #979797;
  }
  .read-more {
    font-size: 1.3rem;
    font-weight: 700;
    position: relative;
    &:hover {
      color: ${colors.secondaryBlue2}
    }
    /* &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: -30px;
      width: 20px;
      height: 1px;
      border: 1px solid ${colors.secondaryBlue2};
      background-color: green;
    } */
  }
`
