import React from "react"
import { IoCloudDownloadOutline } from "react-icons/io5"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

function PressKitAssets({ className, mediaAssets }) {
  const downloadAsset = (url,downloadString) => {
    fetch(url)
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = downloadString;
					a.click();
				});
		});
  }

	const imgSrc = asset => {
		const url = new URL('https:' + asset.file.url);
		url.searchParams.append('w', '326');
		return url.href;
	}

  return (
    <div className={` ${className}`}>
      <Row className="px-lg-5">
        {mediaAssets.map((mediaAsset) => (
          <Col xs={12} md={4} lg={3} className="p-0 mb-5 mx-auto" key={mediaAsset.id}>
            <div className="asset-tile mx-4 position-relative h-100 d-flex justify-content-center align-items-center flex-column bg-gray">
							<img src={imgSrc(mediaAsset.downloadableAsset)} className="asset-preview" />
              <p className="text-center asset-text">
                {mediaAsset.title} <br /> {mediaAsset.subTitle}
              </p>
              <div
              role="button"
                onClick={()=>downloadAsset(mediaAsset.downloadableAsset.file.url,`${mediaAsset.title}${mediaAsset.subTitle?" "+mediaAsset.subTitle:""}`)}
                download={`${mediaAsset.title} ${mediaAsset.subTitle}`}
                id={`download-${mediaAsset.title}-${mediaAsset.subTitle}`.split(" ").join("-").toLowerCase()}
                >
                <IoCloudDownloadOutline
                  size="25"
                  className="download-icon position-absolute primary-blue-text"
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default PressKitAssets
