import styled from "styled-components"
import { colors } from "../../../../utils/styles"

const getColor = ({ variant }) => {
  switch (variant) {
    case "light":
      return "#61EACA"
    case "white":
      return "#DDEAE7"
    case "green":
      return `${colors.secondaryBlue2}`
    default:
      return colors.primaryBlue
  }
}

export const StyledHeadingWithBg = styled.div`
  .bg {
    width: 70px;
  }
  color: ${(props) => getColor(props)};
`
