import React, { useEffect } from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/PressAndMedia/HeroSection/HeroSection"
import PressSection from "../components/PressAndMedia/PressSection/PressSection"
import MediaPostsSection from "../components/PressAndMedia/MediaPostsSection/MediaPostsSection"
import SubscribeFormSection from "../components/PressAndMedia/SubscribeFormSection/SubscribeFormSection"
import PressKitSection from "../components/PressAndMedia/PressKitSection/PressKitSection"
import SEO from "../components/Global/SEO/seo"

const PressAndMedia = () => {
  return (
    <Layout>
      <SEO page="news" />
      <HeroSection />
      <PressSection />
      <MediaPostsSection />
      <SubscribeFormSection />
      <PressKitSection />
    </Layout>
  )
}

export default PressAndMedia
