import styled from "styled-components"

export const HeroSectionStyles = styled.section`
.terms-and-condition {
    h1 {
        font-weight: 800;
        color: #4CB9EF;
        font-size: 2.5rem;
        @media (max-width:992px) {
            font-size: 2rem;
        }
    }
}
`;
