import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const PressKitSectionStyles = styled.section`
  /* background-color: white; */
  .asset-tile {
    /* min-height: 200px; */
    border: 2px solid #979797 ;
    border-radius: 25px;
  }
  .download-icon {
    top: 10px;
    right: 10px;
  }
  .asset-preview {
    /* height: 100%; */
    margin: auto;
  width: 90%;
  }
  .asset-text {
    margin-top: auto;
  }
  
  .asset-list {
    border-bottom: 1px solid #979797;
    border-top: 1px solid #979797;
  }
  .read-more {
    font-size: 1.3rem;
    font-weight: 700;
    position: relative;
    &:hover {
      color: ${colors.secondaryBlue2}
    }
    /* &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: -30px;
      width: 20px;
      height: 1px;
      border: 1px solid ${colors.secondaryBlue2};
      background-color: green;
    } */
  }
`
