import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import { MediaSectionStyles } from "./MediaPostsSection.styles"
import Img from "gatsby-image"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { navigateTo } from "../../../utils/hooks"

const MediaPostsSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(
        filter: { category: { elemMatch: { slug: { eq: "updates" } } } }
        limit: 4
        sort: { fields: publishedOn, order: DESC }
      ) {
        edges {
          node {
            id
            slug
            featured
            title
            publishedOn(formatString: "DD MMM, YYYY")
            category {
              name
            }
            externalLink
          }
        }
      }
    }
  `)
  const mediaPosts = data.allContentfulBlogPost.edges;
  return (
    <MediaSectionStyles>
      <Container className="p-5 px-lg-0">
        {mediaPosts.map(({ node: mediaPost }) => (
          <Row className="media-post py-3">
            <Col xs={12} lg={3} className="px-lg-0">
              <div className="d-flex primary-blue-text my-2 align-items-top h-100">
                <p>{`${mediaPost.publishedOn} . Company Update`}</p>
              </div>
            </Col>
            <Col className="px-lg-0">
              <h5 className="my-2 primary-blue-text media-post-title">{mediaPost.title}</h5>
            </Col>
            <Col xs={12} lg={2} className="px-lg-0">
              <div className="d-flex my-2 align-items-bottom h-100 ">
                <Link
                  className="read-more secondary-blue-2-text mx-3 "
                  to={`/blog/${mediaPost.slug}/`}
                >
                  - Read More
                </Link>
              </div>
            </Col>
          </Row>
        ))}
        <ApButton
          variant="lightBlue"
          className="mt-5 mx-0"
          onClick={(e) => navigateTo(e, "/blog/updates/")}
        >
          View All Press Releases
        </ApButton>
      </Container>
    </MediaSectionStyles>
  )
}

export default MediaPostsSection
