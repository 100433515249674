import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { PressKitSectionStyles } from "./PressKitSection.styles"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import Img from "gatsby-image"
import ApButton from "../../Global/Common/ApButton/ApButton"
import PressKitAssets from "./PressKitAssets"
import { useState } from "react"
import { useRef } from "react"

const PressKitSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulMediaAssets(sort: { fields: order, order: ASC }) {
        nodes {
          id
          title
          subTitle
          downloadableAsset {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const mediaAssets = data.allContentfulMediaAssets.nodes
  const hiddenAnchor = useRef()
  // https://stackoverflow.com/questions/40091000/simulate-click-event-on-react-element
  const handleDownloadAllClick = () => {
    mediaAssets.forEach((mediaAsset, index) => {
      setTimeout(() => {
        fetch(mediaAsset.downloadableAsset.file.url).then((response) => {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement("a")
            a.href = url
            a.download = `${mediaAsset.title} ${mediaAsset.subTitle}`
            a.click()
          })
        })
      }, (index + 1) * 1000)
    })
  }
  return (
    <PressKitSectionStyles >
      <Container className="p-5 px-lg-0">
        <div className="d-flex flex-wrap pb-5 " id="press-kit">
          <HeadingWithBg text="Press Kit" />
          <ApButton
            variant="inverse"
            className="mx-4 mx-md-5 my-2"
          >{`${mediaAssets.length} files`}</ApButton>
          <ApButton
            variant="lightBlue"
            className="ml-auto my-2 px-5"
            onClick={handleDownloadAllClick}
            id="download-all-media-assets"
          >
            Download All
          </ApButton>
          <a ref={hiddenAnchor} className="d-none" />
        </div>
        <Row className="asset-list mb-5">
          <Col>
            <PressKitAssets className="my-5" mediaAssets={mediaAssets} />
          </Col>
        </Row>
      </Container>
    </PressKitSectionStyles>
  )
}

export default PressKitSection
