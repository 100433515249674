import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { StyledHeadingWithBg } from "./HeadingWithBg.styles"

const HeadingWithBg = ({ variant, text, className }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "featuresBackground" } }) {
        nodes {
          childImageSharp {
            fluid(quality: 90, maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const imageData = data.allFile.nodes[0].childImageSharp.fluid

  return (
    <StyledHeadingWithBg
      variant={variant}
      className={`d-flex align-items-center ${className}`}
    >
      <Img fluid={imageData} className="bg position-relative" alt="heading prefix icon"/>
      <h6 className="mb-0 ml-n4">{text}</h6>
    </StyledHeadingWithBg>
  )
}

export default HeadingWithBg
