import React, { useState } from "react"
import { Col, Container, Row, Form } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import { useStaticQuery, graphql } from "gatsby"
import { SubscribeFormSectionStyles } from "./SubscribeFormSection.styles"
import Img from "gatsby-image"
import ApButton from "../../Global/Common/ApButton/ApButton"

const SubscribeFormSection = () => {
  const data = useStaticQuery(graphql`
    {
      file(
        relativeDirectory: { eq: "country_report/map" }
        name: { eq: "Map" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [submitMessage, setSubmitMessage] = useState("");
  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key,data[key]);
    }
    return formData;
  }
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        email: form.email.value,
        "got-ya": form["got-ya"].value
      })
    })
    .then(() => setSubmitMessage("Thank you for your subscription"))
    .catch((error) =>setSubmitMessage("Sorry! something went wrong!"))
  }
  return (
    <SubscribeFormSectionStyles>
      <Container className="py-5 px-5 px-lg-0">
        <Row className="my-5">
          <Col >
            <div className="d-flex flex-column justify-content-center text-center text-lg-left">
              <h2 className="h1 text-white mb-4">Stay up-to date</h2>
              <p className="text-white">
                Receive APEXX Global news in your inbox – including product,
                payments and corporate press releases.
              </p>
            </div>
          </Col>
          <Col >
            <Form className="text-center mx-lg-5" onSubmit={handleSubmit} name="subscribe-updates" netlify-honeypot="got-ya" method="POST" data-netlify="true" >
            <input type="hidden" name="form-name" value="subscribe-updates" />
            <label style={{visibility: "hidden"}}>Don’t fill this out if you're human: <input name="got-ya" /></label>
              <Form.Group className="position-relative py-2">
                <Form.Label hidden>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Your e-mail address"
                  className=" p-4"
                  required={true}
                />
              </Form.Group>
              <p className="text-white">{submitMessage}</p>
              <ApButton variant="green" type="submit" className="my-2 px-5">
                Subscribe
              </ApButton>
            <label style={{visibility: "hidden",transform: "translateX(-100%)"}}>Don’t fill this out if you're human: <input name="got-ya" /></label>
            </Form>
          </Col>
        </Row>
      </Container>
    </SubscribeFormSectionStyles>
  )
}

export default SubscribeFormSection
