import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { ApButton } from "../../Global/Common/ApButton/ApButton"
import { HeroSectionStyles } from "./HeroSection.styles"
import { FiChevronRight } from "react-icons/fi"
import { navigateTo } from "../../../utils/hooks"
import { Link } from "gatsby"

const HeroSection = () => {
  return (
    <HeroSectionStyles className="py-5 px-4 px-lg-2 secondary-blue-3-background ">
      <Container className="terms-and-condition py-5">
        <h1 className="mb-4">Press and Media</h1>
        <p className="mb-5 text-white lead">
          Explore our latest press releases as well as news mentions, our press
          kit and corporate information.
        </p>
        <div className="d-flex flex-wrap align-items-center">
            <ApButton
              variant="green"
              size="md"
              className="px-5"
              role="link"
              onClick={(e) => navigateTo(e, "contactUs")}
            >
              Contact Us
            </ApButton>
            <Link href="#press-kit" className="ml-3 mt-3 mt-lg-0 link text-white">
              Download Press Kit <FiChevronRight strokeWidth = "5"/>
            </Link>
        </div>
      </Container>
    </HeroSectionStyles>
  )
}

export default HeroSection
